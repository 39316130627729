import revive_payload_client_GWQ9jP8w7b from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8n1S8Vxr23 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NTnok1a2MT from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_qL31keSy7b from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.5_rollup@4.24.0_vue@3.5.5_typescri_dzrwml2blpbffkz53msp2qegrq/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_VVTWTO06eX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sS0ccDYdHt from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_y53AnMs2sC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6y7wh4xS2y from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_QFJaad23aE from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.4.5_vue@3.5.5_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_pvRc5NvEaO from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_4hjnohmn2dvycph3c2qcp2ryau/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _01_plugin_wACg4qyRzn from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.22.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.2.0_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import _02_meta_uCL2by5fP6 from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.22.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.2.0_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/02.meta.js";
import _03_define_EXJjj1n5IF from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.22.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.2.0_terser@5.31.1__webpack-sources@3.2.3/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  revive_payload_client_GWQ9jP8w7b,
  unhead_8n1S8Vxr23,
  router_NTnok1a2MT,
  sentry_client_qL31keSy7b,
  payload_client_VVTWTO06eX,
  navigation_repaint_client_sS0ccDYdHt,
  check_outdated_build_client_y53AnMs2sC,
  chunk_reload_client_6y7wh4xS2y,
  plugin_vue3_QFJaad23aE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pvRc5NvEaO,
  _01_plugin_wACg4qyRzn,
  _02_meta_uCL2by5fP6,
  _03_define_EXJjj1n5IF
]